@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "helveticaMediumExtended";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}

.container-bio {
  width:70%;
  margin-right: 15%;
}

.image {
  width: 70px;
  height: 70px;
  margin:auto;
  border-radius:50%;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
  margin-right: 25px;
}

@media only screen and (max-width: 600px) {

  .container-bio {
    width:100%!important;
    margin:auto;
  }
  .container-bio p {
    width:100%;
    text-align: justify;
  }
  .image-bandeau {
    width: 100%;
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
    margin-right: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  }



.image-bandeau {
  width: 100%;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.38);
  margin-right: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.biographie-p {
  font-size: 16px;
  font-family: "helveticaMediumExtended";
  margin-top: 20px;
  margin-bottom: 20px;
}


