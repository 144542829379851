.navigation {
  position: fixed!important; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
 /* background-color: #edebe8;*/
 background-color: #f68c05;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(221, 99, 99, 0.23);
}
.brand-name {
  text-decoration: none;
  color: black;
  margin-left: 1rem;
  text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-family: "helveticaMediumExtended";
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  /*removes default disc bullet for li tags and applies margin to left & right side*/
  list-style-type: none;
  min-width: 150px;
  max-width: 150px;
  margin: 0 1rem;
}
.navigation-menu li a {
  /*increases the surface area of the anchor tag to span more than just the anchor text*/
  text-decoration: none;
  display: block;
  width: 100%;
  margin:auto;
}
.hamburger {
  /*removes default border on button element*/
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f68c05;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
/*positions the icon to the right and center aligns it vertically*/
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #f68c05;
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: #f68c05;
  color: white;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  min-width: 150px;
    max-width: 150px;
    margin: 0 1rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f68c05;
  width:100%;
  /*box-shadow: 0px 8px 0px 2px rgba(0,0,0,0.2);*/
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}


/* Show the dropdown menu on hover */

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
    background-color:  #f68c05;
    text-align: left;
    width:120%;
    margin-left: -20%;
}

.menu-li{
  min-width: 150px;
  max-width: 150px;
}
.menu-li a{
text-align: center;
}

@media screen and (max-width: 768px) {
  .dropdown {
    position: relative;
    display: inline-block;
  min-width:100%;
  margin:0;
  max-width: 100%;
  }
  .menu-li{
    min-width: 100%;
    max-width: 100%;
  }
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
      background-color:  grey;
      text-align: center;
      width:100%;
      margin-left: 0%;
  }
  .hamburger {
    display: block;
  }
  .navigation{
    height:60px;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    /*navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)*/
    position: absolute;
    top: 60px;
    left: 0;
    /*stacks the li tags vertically*/
    flex-direction: column;
   /*makes menu span full height and width*/
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }
  .navigation-menu li {
    /*centers link text and strips off margin*/
    text-align: center;
    margin: 0;
    text-decoration: none;
    min-width: 100%;
  }
  .navigation-menu li a {
    color: black;
    /*increases the surface area of the anchor tag to span the full width of the menu*/
    padding: 1.5rem 0;
    display:block;
    min-width: 100%;
  }
  .navigation-menu li:hover {
    background-color: #eee;
    min-width: 100%;
  }
  .navigation-menu.expanded ul {
    min-width: 100%;
    display: inline-flex;
    align-items: center;

  }
}
