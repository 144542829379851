h4{
  font-family: "helveticaMediumExtended";
  margin-bottom: 25px;
}

.init{
  color:orange;
}

.success{
  color:green;
}

.error{
  color:red
}

.myformlabel{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
}

.myforminput{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
}

.myforminput:active, .myforminput:focus{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
  outline:"none"!important;
}

.checkboxesTitle{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  color:black;
  font-weight: bold;
}

.checkboxesGroup{
  margin-top: 25px;
}

.form-check-label{
  margin-left: 50px;
  font-family: "helveticaMediumExtended";
  font-size: 16px;
}

.myforminputtext{
  font-family: "helveticaMediumExtended";
  font-size: 16px;
  height : 5rem;
  color:"black";
}

.btnFormSend{
  margin-left:75%;
  width:25%;
  height : 5rem;
  background-color: #edebe8;
  border: 1px solid #edebe8;
  color:black;
}

@media (max-width: 768px) {
.btnFormSend
    {
      margin-left:5%;
      width:90%;
    }
}

.btn-outline-success, .btn-outline-success:hover{
  padding-top:8px;
  color:white;
  border-color:black;
  background-color: black;
}

.form-check-input:checked{
  background-color: #cabcab!important;
}

.btn-envoyer{
  font-family: "helveticaMediumExtended";
  font-size:16px;
  height : 5rem;
  color:white!important;
}
